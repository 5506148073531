import React from "react";

function Home() {
  return (
    <div className="home">
      <img alt="blue truck" src="\img\asset\logo.jpg" />
      <div className="phone">
        <a href="mailto:antiqueautoworks@gmail.com">
          antiqueautoworks@gmail.com
        </a>
      </div>
    </div>
  );
}

export default Home;
